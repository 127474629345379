export const logo = ['608 134', `
  <title>coreui react pro</title>
  <g>
    <g style="fill:#00a1ff">
    <?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 902.8 223" style="enable-background:new 0 0 902.8 223;" xml:space="preserve">
    <style type="text/css">
      .st0{fill:#0B9DAE;}
      .st1{fill:#E52E71;}
      .st2{font-family:'ComicSansMS';}
      .st3{font-size:147.91px;}
    </style>
    <path class="st0" d="M-82.3-54.5"/>
    <text transform="matrix(1.17 0 0 1 272.2762 178.3667)" class="st1 st2 st3">OBPMS</text>
    <g transform="translate(0.000000,1268.000000) scale(0.100000,-0.100000)">
      <path class="st1" d="M351.8,12223.5c-38.5-27-66.3-65.4-81.2-111.4c-13.1-40.7-14.3-81.3-3.6-123.5c3.9-15.8,6.4-21.8,19-45.7
        c12.5-23.8,16-29.4,26.9-41.7c12.6-14.6,33.6-33.2,44.4-39.9c41.4-25.5,82.6-37.7,126.8-37.7c5.8,0,10.4,0.4,10.1,0.9
        c-0.2,0.5,1.7,0.7,4.4,0.3c13.9-1.3,58.3,11.4,81.4,23.3c5.8,3,14.7,6.7,19.6,8.3c5.2,1.5,11.7,3.6,14.4,4.6c2.9,0.9,12.2,1.8,21,2
        c13.1,0.5,19.7-0.6,38.9-7c24.6-8.1,49.3-18.9,69.3-29.9c14.8-8.4,92.1-64.7,161.4-117.8c33.5-25.7,43.8-33.6,69.2-52.7
        c8.6-6.5,25.7-19.5,37.7-29.2c12.2-9.6,24-18.6,26.2-20.2c12-8.1,27.3-20.7,38.3-31.9c10.6-10.6,14.3-15.7,25.1-34.8
        c10.9-19.3,13.3-24.6,16.8-38.2c2.2-8.7,6.1-21.6,8.6-28.5c2.6-6.8,4.9-13.8,5.2-15.7c0.9-4.7,4.2-2.6,28.3,19.6
        c18.7,17,23.5,20.7,38.6,28.6c15.6,8.4,18.4,9.2,29.8,10c6.7,0.5,17-0.3,22.6-1.5c5.6-1.5,10.3-2,10.6-1.5
        c0.2,1.1-50.3,41.8-54.5,43.6c-1.1,0.6-13.9,10.5-28.6,22c-14.6,11.5-31.5,24.9-37.4,29.4c-21.5,16.1-35.3,26.9-55.2,42.7
        c-10.9,8.7-22.7,17.7-25.8,19.9c-8.4,6-9.4,6.9-33.1,25.4c-12,9.5-28.1,21.9-35.7,27.7c-27.7,21.1-40.7,31.1-63.3,48.6
        c-12.8,9.9-25.3,19.6-27.8,21.4c-13.5,9.7-102.8,77.3-121.4,91.9c-31.5,24.5-58.5,63.7-69,100.2c-6.3,21.9-8.2,33.9-9.1,62.3
        c-0.8,19-3.7,50.9-4.9,52c-0.2,0.1-2,6.5-4.1,14.3c-5,19-22.3,52.3-34.8,66.7c-4.8,5.7-10,11.8-11.3,13.5c-1.5,1.6-7,7.2-12.6,12.2
        c-29,26.6-67.7,47.1-101.9,53.9c-32,6.4-68.8,7.5-85.1,2.8c-1.6-0.4-6.9-1.4-11.8-2.2c-11.8-1.8-42.9-13.5-57.4-21.3
        C370.2,12235.8,359.1,12228.7,351.8,12223.5z M448.5,12208.4c19.5,3.6,32.5,4.4,49.3,2.5c67.8-7.6,124.4-55.6,143.4-121.6
        c4.8-16.7,5.4-20.6,6.1-42c1-27.9-5.6-54.1-20.1-80.7c-19.7-36.1-49.2-62.6-85.8-76.6c-67-26-139.1-7.4-187.9,48.2
        c-36.5,41.4-49,101.7-31.9,154.7c8.6,27.1,22.7,50.1,44.1,71.9c10.6,11,22.3,18.5,46.2,30.2
        C429,12203.4,436.4,12206.1,448.5,12208.4z"/>
      <path class="st1" d="M229.4,10955.2c-0.7-1.9-3.5-9.8-6.4-17.8c-2.9-8-6.1-19-7.1-24.6c-1.2-5.5-2.5-11.5-3-13.1
        c-1.9-5.7-3.6-38.8-2.7-53.1c1.7-29.8,16.7-73.6,34.2-100.4c37.7-57.8,100.4-96.5,167.3-103c22-2.1,54.7,1,78.5,7.6
        c39.6,11,70.9,29.9,102.5,62.3c13.5,13.8,41,60.5,46.8,79.3c5.9,19.1,9.2,34.9,10.7,51.3c2.5,26.3,3.6,34.1,6.6,48.8
        c1.6,7.9,3.9,16.8,5.1,19.4c1.4,2.7,3.6,9,5.6,14c4.1,11.5,15.4,30.6,23,39.3c3.2,3.7,8,9.3,10.6,12.4c6.4,8.1,20.6,19.9,43.8,36.7
        c19.8,14.4,24.4,17.7,51.6,36.2c8,5.6,24.1,16.8,35.8,25.1c11.7,8.3,25.6,18.2,31,21.8c18.5,12.5,34.8,23.9,55.9,39
        c22.5,16.1,23.8,17,32.8,22.8c3.2,2,18.3,12.6,33.5,23.4s33.3,23.7,40.5,28.6c7,4.9,16.4,11.5,20.9,14.4
        c4.5,3.1,14.4,10.2,22.2,15.7c14.6,10.5,23.7,16.9,55,38.5c10.1,7,24.9,17.4,32.6,22.9c7.9,5.6,20.8,14.7,28.7,20.1
        c8,5.6,27.2,18.9,42.5,29.8c15.4,11.1,29.4,20.9,31,21.8c1.8,1,7.1,4.7,11.9,8c4.7,3.4,28.8,20.2,53.2,37.5
        c24.5,17.2,51,35.9,58.8,41.4c15,10.7,33.1,23.3,38,26.5c1.7,1.1,12.5,8.7,24.2,17c21.3,15.2,37.4,26.5,55.9,39
        c5.4,3.6,18.5,12.9,29.3,20.8c10.8,7.8,25,18,31.7,22.4c6.6,4.5,22.8,16,35.8,25.1c22.4,16,29.7,21.1,53.9,37.7
        c5.5,3.7,24.4,16.9,41.7,29.3c48.7,34.4,82.8,58.5,97.2,68.1c7,4.9,25.5,17.8,40.8,28.8c15.4,11.1,29.4,20.9,31,21.8
        c7.1,4.7,25.5,17.4,45.9,32.2c30.3,21.8,39,27.8,42.6,30c1.6,0.9,15.7,10.9,31.4,22.2c15.6,11.1,30.6,21.2,33.1,22.6
        c2.5,1.2,4.1,2.5,3.5,2.9c-0.5,0.3,1.7,2.1,5,3.9c3.2,1.6,17.1,11.3,31,21.2c22.8,16.4,30.6,21.9,59.5,42
        c4.8,3.3,15.2,10.8,23,16.3c14.7,10.7,32.9,23.4,38,26.5c1.6,0.9,15.6,10.8,31,21.8c15.3,10.9,33.7,23.9,40.8,28.8
        c7,4.9,16.4,11.5,20.9,14.4c4.5,3.1,14.4,10.2,22.2,15.7c7.9,5.6,18.2,13.1,23.3,16.5c4.9,3.5,9.3,6.7,9.5,7.1
        c0.9,1.5-8.1,6.2-13.5,7.1c-6.8,1.1-13.9-0.9-24.9-6.9c-17.6-9.6-49.6-26.3-54.8-28.8c-2.9-1.1-5.4-2.6-5.7-3
        c-0.3-0.5-14.6-8-31.8-17c-17.1-8.8-34.1-17.7-37.3-19.7c-3.4-1.9-11.2-6.1-17.6-9.2c-6.3-3.1-12.6-6.4-13.9-7.2c-1.2-1-8-4.5-15-8
        c-7-3.5-12.8-6.7-13-7.1c-0.3-0.5-9-5.2-19.5-10.3c-10.4-5.2-19.2-9.9-19.4-10.2c-0.2-0.3-8.9-5-19.4-10.2s-19.2-9.9-19.4-10.2
        c-0.3-0.5-4.9-3.1-10.4-5.8c-13.7-6.8-47.1-24.4-54.8-28.8c-3.4-1.9-16.4-9-29.2-15.7c-31.1-16.4-52.3-27.6-64.3-34.1
        c-12-6.3-31.3-16.7-40.4-21.4c-3.5-1.8-11.9-6.2-18.5-9.7c-6.6-3.5-20.6-10.9-31.3-16.5c-10.7-5.6-21.8-11.6-25.1-13.3
        c-6.4-3.5-34.2-18.3-64.7-34.4c-10.7-5.6-23.9-12.6-29.4-15.6c-16.5-8.8-30.1-15.8-35.7-18.4c-3-1.3-5.6-2.9-5.9-3.3
        c-0.4-0.6-6.5-4-13.7-7.5c-7.2-3.5-13.3-6.9-13.5-7.2c-0.3-0.5-8.9-5-19.4-10.2c-10.4-5.2-19.2-9.9-19.4-10.2
        c-0.3-0.5-6.1-3.8-13.1-7.3c-6.7-3.4-13.8-7.1-15.5-8.1c-1.8-1-15.3-8.2-30.3-16.1c-15-8-31.7-16.9-37.2-19.8
        c-5.6-2.9-14.3-7.4-19.4-10.2c-5.2-2.5-15.5-8.1-23-12.1s-22.8-12.1-33.9-18.1c-11.2-5.7-26.2-13.7-33.3-17.8
        c-7.3-3.9-18.7-10.1-25.6-13.7c-13.7-7.2-38-20.2-43.7-23.2c-1.9-1.1-15.6-8.3-30.3-16.1s-31.2-16.6-36.6-19.5
        c-5.6-2.9-14.3-7.6-19.6-10.2c-22-11.1-25.5-14-33.5-27.5c-7.1-12.1-13.1-27.4-24.6-63.4c-2.7-8.5-5.9-17.7-7-20.6
        c-1.1-2.6-3-8.9-4.3-13.9c-3.4-13.8-14.1-39-23.1-54.2c-9.2-15.6-26.3-37.6-35.7-45.8c-3.5-3.1-7.7-7.2-9.6-8.9
        c-1.7-1.9-5.6-5.3-8.8-7.6c-3-2.3-5.8-4.6-6.1-5.1c-0.3-0.5-4.1-3.1-8.8-6.1c-4.6-2.9-17.3-11.8-28.3-19.7
        c-19.2-14-37.7-26.9-43.9-30.9c-1.6-0.9-24.6-16.6-50.9-34.7c-84-57.6-175.4-117.8-201.7-132.8c-37.4-21.2-69.6-29.5-103-26.4
        c-21.3,1.9-39.9,8.1-68.7,23.3c-19.1,9.8-29,14-40.7,17.2c-19,4.8-44.2,7.4-64.4,6.3c-23.4-1.2-61.9-11.8-84.7-23.7
        c-6.7-3.4-39.9-24.8-39.3-25.1c0.5-0.3-2.3-2.5-5.9-5.1c-3.7-2.8-8.1-6.3-9.6-8.3c-1.7-1.9-8.7-9.5-15.4-17
        C251.6,10994.3,233.4,10966.2,229.4,10955.2z M305.3,10972.7c4.3,4.5,9.4,10.1,11.4,12.4c5.1,5.8,31.5,23.8,44.6,30
        c16.5,8.1,34.2,13,53.8,14.5c22.9,1.9,32.6,1.3,54.9-3.8c15.6-3.5,20.3-5.4,37.4-15.1c25.6-14.5,30.4-18.2,43.2-31.7
        c42.3-45.1,56.7-103,40.3-162.2c-5.9-21.4-23.5-51.3-40-67.9c-49.7-50.1-122.8-65.3-184.2-38.6c-30.6,13.3-59.4,37.1-76.1,63
        c-19.6,30.5-29.4,71.4-25.2,105.8c0.8,6.9,1.5,14.1,1.5,15.8c0.2,3.4,10.2,34.1,14.1,43.1
        C284.8,10946.6,296.5,10963.3,305.3,10972.7z M1206.6,11507.8c10.3,5.9,14.9,6.8,28.5,6c12.7-0.7,28-9.3,35.8-20.3
        c10.2-14.8,11.3-37.9,2.5-52.9c-5.9-10.1-19.2-19.3-32.2-22.5c-15.6-3.8-32.8-0.2-42.4,9c-12.3,11.6-17.4,22.8-17.4,38.5
        C1181.5,11482.5,1191.4,11499.3,1206.6,11507.8z"/>
      <path class="st1" d="M1228.3,11541.6c-5.3-10.3-5.9-14.9-3.3-26.2c2.2-10.3,7.1-17,16.7-23.6c13.4-9.2,13.4-9.2,19.1,32
        c2.8,20.3,4.8,37.4,4.5,37.8c-1.5,2-19-2.8-25.6-6.9C1234.6,11551.4,1231.6,11548.1,1228.3,11541.6z"/>
      <path class="st1" d="M1269.6,11522.5c-3-21.6-4.3-37.2-3-37.8c8.3-3.1,30.6,9.8,35.7,20.6c2.9,6.2,4.6,19.5,3.5,25.9
        c-1.3,7.6-14,22.7-22.3,26.2c-4,1.8-7.6,2.9-8,2.6C1275,11559.7,1272.4,11542.9,1269.6,11522.5z"/>
      <path class="st1" d="M1138.7,11396.2c0.7-1,22.6-10.2,35.2-14.9c6.4-2.3,30.7-11.2,54.4-20c23.6-8.6,43.8-16.2,44.8-16.7
        s8.2-3.2,15.8-5.9c7.7-2.8,29-10.5,47.4-17.5c30.7-11.5,42.3-15.7,80-29.5c7.1-2.6,27.4-10.1,45.3-16.8
        c59.6-22.2,126.9-47.1,143.8-53.4c19.1-7.1,28.3-10.4,56.2-20.8c36.2-13.5,59.2-21.9,67.8-24.7c4.7-1.5,8.8-3.1,9.2-3.7
        c0.4-0.6,4.5-2.1,9.2-3.7c8.6-2.8,31.6-11.2,67.8-24.7c32.9-12.4,41.5-15.5,88.3-32.8c11.7-4.3,27.7-10.2,35.5-13.2
        c15.1-5.6,41.6-15.4,62.7-23c7.1-2.6,54.1-19.9,104.7-38.6c142.1-52.3,135-49.9,149.6-50.1c7.1-0.2,13.6,0.3,14.6,1
        c4.9,3.7-5.7,12.6-28,23.2c-12.1,5.7-61.4,31.1-105.1,53.8c-31.6,16.5-57.3,29.7-116.2,59.8c-16.4,8.5-45.8,23.6-65.5,33.8
        c-54.5,28.3-74.8,38.7-109.4,56.3c-17.5,9-52.4,27-77.5,40.2c-25.2,13.1-59.7,31.1-76.6,39.6c-16.9,8.5-46.6,24.1-66,34.5
        c-19.4,10.4-48,25.3-63.3,33.2c-15.3,7.9-40.7,21.2-56.2,29.7c-68,36.8-88,45-121.3,50c-30,4.4-58.6,2.6-80.9-5.2
        c-3.9-1.4-9.4-3.1-12.6-3.9c-3-0.7-10.5-2.8-16.5-4.7c-12.9-3.8-25.7-6.8-48-11.4c-36.2-7.4-88.6-18.5-89.1-19
        C1138.5,11397.1,1138.4,11396.6,1138.7,11396.2z"/>
    </g>
    </svg>
    
    </g>
  </g>
`]
