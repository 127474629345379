
export const logoNegative = ['1800 432', `
  <title>OBPMS logo</title>
  <g>
  <?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1036.3 249.1" style="enable-background:new 0 0 1036.3 249.1;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#E52E71;}
    .st1{font-family:'ComicSansMS';}
    .st2{font-size:175.4234px;}
  </style>
  <text transform="matrix(1.17 0 0 1 293.3155 182.5518)" class="st0 st1 st2">OBPMS</text>
  <g transform="translate(0.000000,1268.000000) scale(0.100000,-0.100000)">
    <path class="st0" d="M285.3,12332c-45-31.5-77.5-76.5-94.9-130.3c-15.3-47.6-16.8-95.1-4.2-144.4c4.5-18.4,7.5-25.5,22.2-53.4
      c14.6-27.9,18.7-34.4,31.4-48.8c14.8-17,39.3-38.8,51.9-46.7c48.4-29.8,96.6-44.1,148.3-44.1c6.8,0,12.1,0.5,11.8,1.1
      c-0.3,0.5,1.9,0.8,5.2,0.4c16.2-1.5,68.1,13.3,95.2,27.3c6.8,3.6,17.2,7.8,23,9.7c6.1,1.8,13.7,4.2,16.9,5.3
      c3.4,1.1,14.3,2.1,24.5,2.3c15.4,0.6,23.1-0.8,45.5-8.2c28.8-9.4,57.7-22.1,81-34.9c17.3-9.9,107.8-75.6,188.8-137.7
      c39.1-30,51.3-39.3,81-61.6c10.1-7.6,30-22.9,44.1-34.2c14.3-11.2,28-21.8,30.7-23.7c14.1-9.5,31.9-24.2,44.8-37.3
      c12.4-12.5,16.7-18.4,29.3-40.8c12.8-22.5,15.6-28.8,19.7-44.6c2.5-10.1,7.2-25.2,10-33.3c3-8,5.7-16.2,6.1-18.3
      c1-5.5,4.9-3.1,33.1,23c21.8,19.9,27.5,24.3,45.1,33.5c18.2,9.8,21.5,10.8,34.8,11.7c7.9,0.6,19.8-0.4,26.4-1.8
      c6.5-1.7,12-2.4,12.4-1.7c0.3,1.3-58.9,48.9-63.7,51c-1.3,0.7-16.3,12.3-33.4,25.8c-17.1,13.5-36.8,29.1-43.8,34.4
      c-25.1,18.9-41.3,31.4-64.5,49.9c-12.8,10.1-26.5,20.7-30.2,23.2c-9.8,7-11,8-38.8,29.7c-14,11.1-32.8,25.6-41.8,32.4
      c-32.4,24.7-47.6,36.3-74,56.8c-15,11.6-29.5,22.9-32.6,25c-15.8,11.4-120.2,90.4-142,107.5c-36.8,28.7-68.4,74.6-80.7,117.2
      c-7.4,25.6-9.6,39.7-10.6,72.8c-0.9,22.2-4.3,59.5-5.7,60.9c-0.3,0.1-2.4,7.6-4.8,16.7c-5.8,22.2-26,61.2-40.7,78.1
      c-5.6,6.7-11.7,13.8-13.2,15.8c-1.7,1.9-8.1,8.4-14.7,14.3c-33.9,31.1-79.2,55.1-119.2,63.1c-37.4,7.5-80.4,8.8-99.5,3.2
      c-1.8-0.5-8-1.6-13.8-2.6c-13.9-2.1-50.2-15.8-67.2-24.9C306.8,12346.3,293.8,12338.1,285.3,12332z M398.4,12314.4
      c22.8,4.2,38,5.2,57.7,2.9c79.3-8.9,145.5-65,167.7-142.2c5.6-19.5,6.3-24.1,7.1-49.2c1.2-32.6-6.5-63.3-23.4-94.4
      c-23-42.2-57.5-73.2-100.4-89.6c-78.4-30.3-162.7-8.6-219.8,56.4c-42.7,48.5-57.3,119-37.4,180.9c10,31.7,26.6,58.6,51.5,84.1
      c12.4,12.8,26.1,21.6,54,35.3C375.5,12308.5,384.2,12311.6,398.4,12314.4z"/>
    <path class="st0" d="M142.1,10848.7c-0.8-2.2-4.1-11.5-7.5-20.8c-3.3-9.3-7.1-22.3-8.3-28.8c-1.4-6.4-2.9-13.5-3.5-15.3
      c-2.2-6.6-4.2-45.4-3.2-62.1c2-34.9,19.5-86,40-117.4c44.1-67.6,117.5-112.8,195.7-120.5c25.7-2.4,64,1.2,91.8,8.9
      c46.3,12.9,82.9,35,119.9,72.8c15.8,16.2,48,70.8,54.7,92.8c6.9,22.4,10.8,40.8,12.5,60c3,30.7,4.2,39.8,7.7,57
      c1.8,9.3,4.6,19.6,5.9,22.7c1.6,3.1,4.3,10.5,6.5,16.4c4.8,13.5,18,35.8,26.9,46c3.7,4.3,9.3,10.9,12.4,14.6
      c7.5,9.5,24.1,23.3,51.3,42.9c23.2,16.9,28.6,20.7,60.3,42.3c9.4,6.5,28.2,19.7,41.9,29.4c13.7,9.7,29.9,21.3,36.2,25.5
      c21.6,14.7,40.8,27.9,65.4,45.6c26.3,18.9,27.8,19.9,38.3,26.7c3.8,2.3,21.5,14.8,39.2,27.4c17.8,12.6,39,27.8,47.4,33.4
      c8.2,5.7,19.2,13.4,24.4,16.9c5.3,3.6,16.9,11.9,26,18.4c17.1,12.3,27.7,19.7,64.3,45c11.8,8.2,29.1,20.4,38.2,26.8
      c9.2,6.6,24.3,17.2,33.6,23.5c9.4,6.5,31.8,22.1,49.7,34.9c18,13,34.4,24.5,36.2,25.5c2.1,1.2,8.3,5.5,13.9,9.3
      c5.5,4,33.7,23.7,62.2,43.9c28.7,20.1,59.7,42,68.7,48.4c17.5,12.5,38.7,27.2,44.5,31c1.9,1.3,14.6,10.1,28.3,19.9
      c24.9,17.8,43.8,31,65.4,45.6c6.3,4.2,21.7,15.1,34.3,24.3s29.2,21,37.1,26.2c7.7,5.3,26.6,18.7,41.9,29.4
      c26.2,18.7,34.8,24.7,63.1,44.1c6.4,4.4,28.5,19.8,48.8,34.2c56.9,40.3,96.9,68.4,113.6,79.7c8.2,5.7,29.9,20.9,47.7,33.6
      c18,13,34.4,24.5,36.2,25.5c8.3,5.5,29.8,20.4,53.7,37.6c35.5,25.5,45.6,32.5,49.8,35.1c1.8,1.1,18.3,12.8,36.7,26
      c18.3,13,35.8,24.8,38.7,26.5c3,1.4,4.8,2.9,4.1,3.3c-0.5,0.3,1.9,2.5,5.8,4.5c3.8,1.9,20,13.2,36.3,24.8
      c26.7,19.1,35.8,25.6,69.6,49.1c5.7,3.9,17.8,12.6,26.9,19c17.2,12.5,38.5,27.3,44.5,31c1.8,1.1,18.2,12.6,36.2,25.5
      c17.9,12.8,39.4,28,47.7,33.6c8.2,5.7,19.2,13.4,24.4,16.9c5.3,3.6,16.9,11.9,26,18.3c9.2,6.6,21.3,15.3,27.2,19.3
      c5.8,4.1,10.8,7.8,11.2,8.3c1.1,1.8-9.4,7.2-15.8,8.3c-7.9,1.3-16.2-1-29.1-8.1c-20.6-11.2-58-30.7-64.1-33.6
      c-3.4-1.3-6.3-3-6.7-3.5c-0.3-0.5-17.1-9.4-37.2-19.9c-20-10.3-39.9-20.7-43.7-23.1c-4-2.2-13.1-7.2-20.5-10.7
      c-7.4-3.6-14.7-7.4-16.3-8.4c-1.4-1.1-9.4-5.3-17.6-9.4c-8.2-4.1-14.9-7.8-15.2-8.3c-0.3-0.5-10.5-6-22.8-12.1
      c-12.2-6-22.4-11.5-22.6-11.9c-0.2-0.4-10.4-5.9-22.6-11.9c-12.2-6-22.4-11.5-22.6-11.9c-0.3-0.5-5.7-3.6-12.2-6.8
      c-16-7.9-55.1-28.6-64.1-33.6c-4-2.2-19.2-10.6-34.1-18.3c-36.3-19.2-61.1-32.3-75.2-39.8c-14-7.4-36.6-19.6-47.2-25
      c-4.1-2.1-13.9-7.2-21.6-11.3c-7.7-4.1-24.1-12.7-36.6-19.3s-25.5-13.6-29.4-15.6c-7.4-4-40-21.4-75.7-40.3
      c-12.5-6.6-28-14.8-34.3-18.2c-19.3-10.3-35.2-18.5-41.8-21.5c-3.5-1.5-6.5-3.4-6.9-3.9c-0.4-0.7-7.6-4.7-16-8.8
      c-8.5-4.1-15.6-8.1-15.8-8.5c-0.3-0.5-10.4-5.9-22.6-11.9c-12.2-6-22.4-11.5-22.6-11.9c-0.3-0.5-7.2-4.4-15.4-8.5
      c-7.9-4-16.2-8.3-18.1-9.5c-2.1-1.2-17.9-9.6-35.4-18.8c-17.6-9.4-37.1-19.7-43.5-23.2c-6.5-3.4-16.7-8.7-22.6-11.9
      c-6.1-2.9-18.1-9.5-26.9-14.2s-26.6-14.1-39.6-21.1c-13.1-6.7-30.6-16.1-39-20.8c-8.5-4.6-21.9-11.9-29.9-16
      c-16-8.4-44.4-23.6-51.1-27.1c-2.2-1.3-18.2-9.7-35.4-18.8s-36.4-19.4-42.8-22.8c-6.5-3.4-16.8-8.9-22.9-12
      c-25.7-13-29.8-16.3-39.2-32.2c-8.3-14.1-15.3-32.1-28.8-74.2c-3.2-9.9-6.9-20.7-8.2-24c-1.3-3.1-3.5-10.4-5-16.3
      c-4-16.2-16.5-45.6-27.1-63.4c-10.7-18.2-30.7-43.9-41.7-53.6c-4.1-3.6-9-8.4-11.2-10.5c-2-2.2-6.6-6.2-10.3-8.9
      c-3.5-2.7-6.8-5.4-7.1-5.9c-0.3-0.5-4.8-3.7-10.2-7.2c-5.3-3.3-20.2-13.8-33.1-23.1c-22.4-16.4-44.1-31.5-51.4-36.1
      c-1.8-1.1-28.7-19.4-59.6-40.6c-98.2-67.4-205.1-137.8-235.9-155.3c-43.7-24.7-81.4-34.5-120.5-30.9c-24.9,2.2-46.6,9.5-80.4,27.2
      c-22.3,11.5-33.9,16.4-47.5,20.1c-22.2,5.6-51.7,8.6-75.3,7.4c-27.3-1.4-72.4-13.8-99.1-27.7c-7.9-4-46.7-29-46-29.4
      c0.5-0.3-2.7-3-6.9-6c-4.3-3.2-9.4-7.4-11.3-9.7c-2-2.2-10.1-11.1-18-19.9C168.2,10894.4,146.8,10861.6,142.1,10848.7z
       M230.9,10869.2c5,5.2,11,11.8,13.4,14.5c6,6.8,36.8,27.8,52.2,35.1c19.3,9.5,40,15.2,62.9,17c26.8,2.2,38.2,1.5,64.2-4.5
      c18.3-4.1,23.7-6.3,43.7-17.6c29.9-16.9,35.6-21.2,50.6-37c49.5-52.7,66.3-120.4,47.1-189.7c-6.8-25.1-27.5-60-46.8-79.4
      c-58.1-58.6-143.7-76.4-215.4-45.1c-35.8,15.6-69.4,43.4-89.1,73.7c-23,35.7-34.4,83.6-29.5,123.7c0.9,8.1,1.8,16.5,1.7,18.5
      c0.2,4,12,39.8,16.5,50.4C206.9,10838.6,220.6,10858.2,230.9,10869.2z M1285,11495c12,6.9,17.5,8,33.3,7.1
      c14.9-0.8,32.8-10.9,41.8-23.7c11.9-17.4,13.3-44.4,3-61.9c-6.9-11.8-22.5-22.6-37.7-26.4c-18.2-4.4-38.4-0.2-49.6,10.5
      c-14.4,13.5-20.3,26.7-20.4,45C1255.6,11465.4,1267.1,11485.1,1285,11495z"/>
    <path class="st0" d="M1310.4,11534.5c-6.3-12-6.9-17.4-3.8-30.7c2.6-12,8.3-19.9,19.5-27.6c15.7-10.8,15.7-10.8,22.4,37.4
      c3.3,23.8,5.6,43.7,5.3,44.2c-1.7,2.3-22.2-3.3-30-8.1C1317.8,11546,1314.3,11542.1,1310.4,11534.5z"/>
    <path class="st0" d="M1358.6,11512.2c-3.5-25.2-5-43.5-3.5-44.2c9.7-3.6,35.8,11.4,41.8,24.1c3.4,7.2,5.4,22.8,4.1,30.3
      c-1.6,8.9-16.4,26.5-26.1,30.7c-4.7,2.1-8.8,3.4-9.3,3.1C1365,11555.7,1361.9,11536,1358.6,11512.2z"/>
    <path class="st0" d="M1205.6,11364.5c0.9-1.2,26.4-12,41.2-17.4c7.5-2.7,35.9-13.1,63.6-23.3c27.6-10,51.2-19,52.4-19.6
      c1.2-0.6,9.5-3.7,18.4-6.9c9-3.3,33.9-12.3,55.4-20.4c35.8-13.4,49.5-18.4,93.6-34.5c8.3-3.1,32.1-11.9,53-19.6
      c69.7-26,148.4-55.1,168.2-62.4c22.4-8.3,33.1-12.1,65.7-24.4c42.3-15.8,69.2-25.6,79.2-28.9c5.5-1.8,10.3-3.6,10.8-4.3
      s5.3-2.5,10.8-4.3c10-3.3,36.9-13.1,79.2-28.9c38.5-14.5,48.5-18.1,103.2-38.3c13.6-5,32.4-11.9,41.5-15.4
      c17.7-6.6,48.7-18,73.3-26.9c8.3-3.1,63.3-23.3,122.4-45.1c166.2-61.1,157.8-58.3,174.9-58.6c8.2-0.3,15.9,0.3,17,1.2
      c5.8,4.4-6.7,14.7-32.8,27.2c-14.2,6.7-71.9,36.3-122.9,62.9c-36.9,19.3-67,34.8-136,69.9c-19.2,9.9-53.6,27.6-76.6,39.5
      c-63.7,33.1-87.5,45.3-127.9,65.9c-20.4,10.5-61.3,31.6-90.6,47.1c-29.5,15.3-69.8,36.3-89.5,46.3c-19.8,10-54.5,28.1-77.2,40.3
      c-22.7,12.2-56.1,29.5-74,38.8c-17.9,9.3-47.6,24.8-65.7,34.7c-79.5,43-102.9,52.6-141.8,58.5c-35.1,5.2-68.5,3-94.6-6.1
      c-4.5-1.6-11-3.6-14.7-4.6c-3.5-0.8-12.3-3.3-19.2-5.5c-15.1-4.4-30.1-7.9-56.1-13.3c-42.3-8.6-103.6-21.7-104.2-22.2
      C1205.3,11365.6,1205.2,11365,1205.6,11364.5z"/>
  </g>
  </svg>
  
   </g>
`]
