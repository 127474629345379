export const sygnet = ['160 160', `
  <title>coreui logo</title>
  <g>
    <g style="fill:#fff;">
    <?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 22.3 25" style="enable-background:new 0 0 22.3 25;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#E52E71;}
</style>
<g id="Layer_2_1_">
	<g id="Layer_1-2">
		<path class="st0" d="M21,5.3l-8.5-4.9c-0.8-0.5-1.8-0.5-2.6,0L1.3,5.3C0.5,5.7,0,6.6,0,7.6v9.8c0,0.9,0.5,1.8,1.3,2.3l8.5,4.9
			c0.8,0.5,1.8,0.5,2.6,0l8.5-4.9c0.8-0.5,1.3-1.3,1.3-2.3V7.6C22.3,6.6,21.8,5.7,21,5.3z M20.6,17.4c0,0.3-0.2,0.6-0.4,0.8
			l-8.5,4.9c-0.3,0.2-0.6,0.2-0.9,0l-8.5-4.9c-0.3-0.2-0.4-0.4-0.4-0.8V7.6c0-0.3,0.2-0.6,0.4-0.8l8.5-4.9c0.3-0.2,0.6-0.2,0.9,0
			l8.5,4.9c0.3,0.2,0.4,0.4,0.4,0.8V17.4z"/>
		<g transform="translate(0.000000,1268.000000) scale(0.100000,-0.100000)">
			<path class="st0" d="M21.4,12589.9c-2-3.1-3-6.7-2.7-10.5c0.2-3.4,1.3-6.4,3.3-9.2c0.7-1,1.1-1.4,2.7-2.8s2-1.7,3.2-2.3
				c1.3-0.7,3.4-1.5,4.4-1.6c3.8-0.7,7.1-0.4,10.4,0.9c0.4,0.2,0.7,0.3,0.7,0.4s0.1,0.1,0.3,0.2c1.1,0.3,3.9,2.5,5.3,4.1
				c0.3,0.4,0.9,0.9,1.2,1.2c0.3,0.3,0.8,0.6,0.9,0.8c0.2,0.2,0.8,0.5,1.5,0.8c0.9,0.4,1.5,0.5,3.1,0.6c2,0.1,4.2,0,5.9-0.2
				c1.3-0.2,8.6-2.1,15.2-4c3.2-0.9,4.2-1.2,6.6-1.9c0.8-0.2,2.4-0.7,3.6-1.1c1.2-0.3,2.3-0.7,2.5-0.7c1.1-0.2,2.6-0.7,3.7-1.2
				c1.1-0.5,1.5-0.7,2.8-1.8c1.4-1.1,1.7-1.4,2.3-2.3c0.4-0.6,1.1-1.4,1.4-1.8c0.4-0.4,0.8-0.9,0.8-1c0.2-0.3,0.4-0.1,1.5,2.3
				c0.9,1.8,1.1,2.2,2,3.2c0.9,1.1,1.1,1.2,1.9,1.6c0.5,0.2,1.3,0.5,1.7,0.5c0.5,0.1,0.8,0.1,0.8,0.2c0,0.1-4.9,1.6-5.2,1.6
				c-0.1,0-1.3,0.4-2.7,0.8c-1.4,0.4-3,0.9-3.6,1.1c-2,0.6-3.4,1-5.3,1.5c-1.1,0.3-2.2,0.6-2.5,0.7c-0.8,0.2-0.9,0.2-3.2,0.9
				c-1.2,0.4-2.7,0.8-3.4,1c-2.6,0.7-3.9,1.1-6,1.7c-1.2,0.4-2.4,0.7-2.7,0.8c-1.3,0.3-9.8,2.7-11.5,3.2c-3,0.9-6.1,3-7.9,5.4
				c-1.1,1.4-1.6,2.2-2.5,4.3c-0.6,1.4-1.7,3.6-1.9,3.7c0,0-0.3,0.4-0.7,0.9c-0.9,1.2-3.1,3.2-4.5,3.9c-0.5,0.3-1.1,0.6-1.2,0.7
				c-0.2,0.1-0.7,0.3-1.3,0.5c-2.9,1.1-6.3,1.5-9,1c-2.5-0.5-5.3-1.4-6.3-2.2c-0.1-0.1-0.5-0.3-0.8-0.5c-0.8-0.5-2.8-2.2-3.6-3.2
				C22.3,12591.3,21.7,12590.5,21.4,12589.9z M28.9,12591.6c1.3,0.8,2.3,1.3,3.5,1.6c5.2,1.4,10.7-0.5,14-4.8c0.8-1.1,1-1.4,1.7-2.9
				c0.9-2,1.2-4.1,0.9-6.5c-0.4-3.2-1.8-6-4.1-8.1c-4.2-3.8-10-4.6-15.2-1.9c-3.9,2-6.5,6-6.8,10.4c-0.2,2.2,0.2,4.3,1.2,6.5
				c0.5,1.1,1.1,2,2.5,3.5C27.6,12590.7,28.1,12591.1,28.9,12591.6z"/>
			<path class="st0" d="M26,12530.8c-0.1-0.1-0.4-0.7-0.7-1.3s-0.7-1.4-0.9-1.8c-0.2-0.4-0.4-0.9-0.4-1c-0.2-0.4-0.8-3-1-4.1
				c-0.3-2.3,0.3-5.9,1.2-8.3c2.1-5,6.4-8.9,11.5-10.3c1.7-0.5,4.3-0.7,6.2-0.5c3.2,0.3,5.9,1.3,8.8,3.4c1.2,0.9,4,4.1,4.7,5.5
				c0.7,1.4,1.2,2.6,1.6,3.8c0.6,2,0.8,2.6,1.2,3.7c0.2,0.6,0.5,1.2,0.7,1.4c0.1,0.2,0.4,0.6,0.6,1c0.5,0.8,1.6,2.2,2.3,2.7
				c0.3,0.2,0.7,0.6,1,0.8c0.6,0.5,1.9,1.3,3.9,2.2c1.7,0.8,2.1,1,4.5,2.1c0.7,0.3,2.1,1,3.1,1.4c1,0.5,2.2,1.1,2.7,1.3
				c1.6,0.7,3,1.4,4.9,2.2c2,0.9,2.1,1,2.9,1.3c0.3,0.1,1.6,0.7,2.9,1.3s2.9,1.4,3.5,1.6c0.6,0.3,1.4,0.7,1.8,0.8
				c0.4,0.2,1.3,0.6,1.9,0.9c1.3,0.6,2.1,1,4.8,2.2c0.9,0.4,2.2,1,2.9,1.3c0.7,0.3,1.8,0.8,2.5,1.2c0.7,0.3,2.4,1.1,3.7,1.7
				c1.3,0.6,2.6,1.2,2.7,1.3c0.2,0.1,0.6,0.3,1,0.5c0.4,0.2,2.5,1.2,4.6,2.2c2.1,1,4.5,2.1,5.1,2.4c1.3,0.6,2.9,1.3,3.3,1.5
				c0.1,0.1,1.1,0.5,2.1,1c1.9,0.9,3.3,1.5,4.9,2.2c0.5,0.2,1.6,0.7,2.6,1.2c0.9,0.5,2.2,1,2.8,1.3c0.6,0.3,2,0.9,3.1,1.4
				c2,0.9,2.6,1.2,4.7,2.2c0.5,0.2,2.1,1,3.6,1.7c4.3,2,7.2,3.4,8.5,3.9c0.6,0.3,2.2,1,3.6,1.7c1.3,0.6,2.6,1.2,2.7,1.3
				c0.6,0.3,2.2,1,4,1.8c2.7,1.3,3.4,1.6,3.7,1.7c0.1,0,1.4,0.6,2.7,1.3c1.4,0.6,2.7,1.2,2.9,1.3c0.2,0.1,0.4,0.1,0.3,0.2
				c0,0,0.2,0.1,0.4,0.2c0.3,0.1,1.5,0.6,2.7,1.2c2,0.9,2.7,1.3,5.2,2.4c0.4,0.2,1.3,0.6,2,0.9c1.3,0.6,2.9,1.3,3.3,1.5
				c0.1,0,1.4,0.6,2.7,1.3c1.3,0.6,2.9,1.4,3.6,1.7c0.6,0.3,1.4,0.7,1.8,0.8c0.4,0.2,1.3,0.6,1.9,0.9c0.7,0.3,1.6,0.8,2,1
				c0.4,0.2,0.8,0.4,0.8,0.4c0.1,0.1-0.5,0.6-0.9,0.7c-0.5,0.2-1.1,0.1-2-0.2c-1.5-0.5-4.2-1.3-4.6-1.5c-0.2,0-0.5-0.1-0.5-0.2
				c0,0-1.2-0.4-2.7-0.9c-1.5-0.4-2.9-0.9-3.2-1c-0.3-0.1-1-0.3-1.5-0.5s-1.1-0.3-1.2-0.4c-0.1-0.1-0.7-0.2-1.3-0.4
				c-0.6-0.2-1.1-0.3-1.1-0.4c0,0-0.8-0.3-1.7-0.5c-0.9-0.3-1.6-0.5-1.6-0.5c0,0-0.8-0.3-1.6-0.5c-0.9-0.3-1.6-0.5-1.6-0.5
				c0,0-0.4-0.2-0.9-0.3c-1.2-0.3-4-1.2-4.6-1.5c-0.3-0.1-1.4-0.5-2.5-0.8c-2.6-0.8-4.4-1.4-5.5-1.7c-1-0.3-2.7-0.9-3.4-1.1
				c-0.3-0.1-1-0.3-1.6-0.5c-0.6-0.2-1.7-0.6-2.7-0.8s-1.9-0.6-2.1-0.7c-0.5-0.2-2.9-0.9-5.5-1.8c-0.9-0.3-2-0.6-2.5-0.8
				c-1.4-0.4-2.6-0.8-3-0.9c-0.3-0.1-0.5-0.1-0.5-0.2c0,0-0.6-0.2-1.2-0.4s-1.1-0.3-1.1-0.4c0,0-0.8-0.3-1.6-0.5
				c-0.9-0.3-1.6-0.5-1.6-0.5c0,0-0.5-0.2-1.1-0.4c-0.6-0.2-1.2-0.4-1.3-0.4c-0.2-0.1-1.3-0.4-2.6-0.8c-1.3-0.4-2.7-0.9-3.2-1
				c-0.5-0.1-1.2-0.4-1.6-0.5c-0.4-0.1-1.3-0.4-2-0.6c-0.6-0.2-1.9-0.6-2.9-0.9c-0.9-0.3-2.2-0.7-2.8-0.9c-0.6-0.2-1.6-0.5-2.2-0.7
				c-1.2-0.4-3.2-1-3.7-1.2c-0.2-0.1-1.3-0.4-2.6-0.8s-2.6-0.8-3.1-1c-0.5-0.1-1.2-0.4-1.7-0.5c-1.9-0.6-2.2-0.7-3-1.7
				c-0.7-0.8-1.4-1.9-2.8-4.6c-0.3-0.6-0.7-1.3-0.8-1.5c-0.1-0.2-0.4-0.6-0.5-1c-0.5-1-1.6-2.8-2.6-3.9c-0.9-1.1-2.6-2.5-3.4-3
				c-0.3-0.2-0.7-0.4-0.9-0.6c-0.2-0.1-0.5-0.3-0.8-0.5c-0.3-0.1-0.5-0.3-0.5-0.3c0,0-0.4-0.2-0.8-0.4c-0.4-0.2-1.5-0.7-2.5-1.1
				c-1.7-0.8-3.3-1.6-3.8-1.8c-0.1,0-2.1-0.9-4.4-2c-7.3-3.3-15.2-6.7-17.5-7.4c-3.2-1.1-5.8-1.3-8.3-0.6c-1.6,0.4-3,1.2-5,2.8
				c-1.3,1-2,1.5-2.9,1.9c-1.4,0.6-3.3,1.2-4.9,1.4c-1.8,0.2-5,0-6.9-0.6c-0.6-0.2-3.4-1.4-3.4-1.4c0,0-0.2-0.2-0.5-0.3
				c-0.3-0.2-0.7-0.4-0.9-0.5c-0.2-0.1-0.8-0.6-1.4-1.1C28.2,12533.5,26.4,12531.6,26,12530.8z M32.1,12531.1
				c0.4,0.3,0.9,0.6,1.1,0.8c0.5,0.4,2.8,1.4,3.9,1.7c1.4,0.4,2.8,0.5,4.4,0.4c1.8-0.2,2.5-0.4,4.2-1.1c1.2-0.5,1.5-0.7,2.7-1.7
				c1.8-1.5,2.1-1.8,2.9-3.1c2.6-4.1,2.9-8.8,0.8-13.1c-0.8-1.6-2.5-3.6-4.1-4.7c-4.6-3.2-10.4-3.3-14.8-0.4c-2.2,1.5-4.1,3.7-5,6
				c-1.1,2.6-1.3,5.9-0.5,8.5c0.2,0.5,0.3,1.1,0.3,1.2c0.1,0.3,1.3,2.5,1.7,3.1C30.1,12529.4,31.3,12530.5,32.1,12531.1z
				 M109.4,12559.9c0.9,0.3,1.3,0.3,2.3,0.1c1-0.2,2-1.1,2.5-2.1c0.6-1.3,0.3-3.1-0.5-4.1c-0.6-0.7-1.8-1.2-2.8-1.3
				c-1.3-0.1-2.5,0.4-3.2,1.3c-0.8,1.1-1,2-0.8,3.2C107.1,12558.3,108.1,12559.4,109.4,12559.9z"/>
			<path class="st0" d="M107.6,12557.1c-0.2-0.9-0.2-1.2,0.2-2.1c0.3-0.8,0.8-1.2,1.7-1.5c1.2-0.5,1.2-0.5,0.9,2.8
				c-0.1,1.6-0.2,3-0.3,3c-0.1,0.1-1.4-0.5-1.9-1C107.9,12557.9,107.7,12557.6,107.6,12557.1z"/>
			<path class="st0" d="M111.1,12556.3c0.1-1.7,0.3-2.9,0.4-3c0.7-0.1,2.2,1.3,2.4,2.2c0.1,0.5,0,1.6-0.2,2.1
				c-0.2,0.6-1.5,1.5-2.1,1.7c-0.3,0.1-0.6,0.1-0.7,0.1C110.9,12559.2,110.9,12557.9,111.1,12556.3z"/>
			<path class="st0" d="M114.6,12549.2c0.1-0.1,1.9-0.4,3-0.6c0.5-0.1,2.5-0.4,4.5-0.6c2-0.3,3.6-0.5,3.7-0.5c0.1,0,0.7-0.1,1.3-0.2
				c0.6-0.1,2.4-0.3,3.9-0.6c2.5-0.4,3.5-0.5,6.6-0.9c0.6-0.1,2.3-0.3,3.8-0.5c5-0.7,10.5-1.5,12-1.7c1.6-0.2,2.3-0.3,4.7-0.7
				c3-0.4,4.9-0.7,5.6-0.8c0.4,0,0.7-0.1,0.8-0.1s0.4-0.1,0.8-0.1c0.7-0.1,2.6-0.3,5.6-0.8c2.7-0.4,3.4-0.5,7.3-1.1
				c1-0.1,2.3-0.3,2.9-0.4c1.3-0.2,3.5-0.5,5.2-0.7c0.6-0.1,4.5-0.6,8.7-1.2c11.8-1.7,11.2-1.6,12.3-1.4c0.5,0.1,1,0.2,1.1,0.3
				c0.3,0.4-0.6,0.9-2.5,1.3c-1,0.2-5.2,1.4-9,2.4c-2.7,0.8-4.9,1.3-9.9,2.7c-1.4,0.4-3.9,1.1-5.6,1.5c-4.7,1.3-6.4,1.7-9.3,2.5
				c-1.5,0.4-4.5,1.2-6.6,1.8c-2.2,0.6-5.1,1.4-6.5,1.8c-1.4,0.4-4,1.1-5.7,1.6c-1.7,0.5-4.1,1.2-5.4,1.5c-1.3,0.4-3.5,1-4.8,1.4
				c-5.8,1.7-7.5,2-10.2,1.8c-2.4-0.2-4.6-0.8-6.1-1.7c-0.3-0.2-0.7-0.4-0.9-0.5c-0.2-0.1-0.8-0.4-1.2-0.6c-0.9-0.5-1.9-0.9-3.5-1.7
				C118.5,12551.1,114.7,12549.3,114.6,12549.2C114.6,12549.3,114.6,12549.2,114.6,12549.2z"/>
		</g>
	</g>
</g>
</svg>

    </g>
  </g>
`]
